import { makeStyles } from '@material-ui/core/styles';
import { RED } from '@theme_color';

const useStyles = makeStyles(() => ({
    noMargin: {
        margin: 0,
    },
    finalPriceRed: {
        color: RED,
        display: 'block',
        lineHeight: '10px',
    },
    oldPrice: {
        fontSize: 10,
        display: 'block',
    },
}));

export default useStyles;
